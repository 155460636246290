@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #00336B;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(229, 231, 235);
  border-radius: 20px;
  border: 2px solid #00336B;
  overflow-y: auto;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #00336B rgb(229, 231, 235);
}

.scrollPretty::-webkit-scrollbar {
  width: 8px;
}

.scrollPretty::-webkit-scrollbar-track {
  background: #00336B;
}

.scrollPretty::-webkit-scrollbar-thumb {
  background-color: rgb(229, 231, 235);
  border-radius: 20px;
  border: 2px solid #00336B;
  overflow-y: auto;
}

.scrollPretty {
  scrollbar-width: thin;
  scrollbar-color: #00336B rgb(229, 231, 235);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.indicator {
  height: 4px;
  border-radius: 4px;
  width: 15%;
  margin-right: 8px;
}

.indicator-container {
  flex-direction: row;
  display: flex;
}

.table-container {
  overflow-x: auto;
}

table {
  min-width: 100%;
}

.pattern-background {
  background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(0, 0, 0, 0.1) 3px, rgba(0, 0, 0, 0.1) 6px);
}

.lio-alert-overlay {
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.lio-alert-overlay .react-confirm-alert-body {
  padding: 25px;
}

.lio-alert-overlay h1 {
  font-size: 20px;
  color: black;
  margin-bottom: 7px;
  text-transform: uppercase;
  font-weight: bold;
}

.lio-alert-overlay .react-confirm-alert-button-group > button {
  background-color: #00336B;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  padding: 8px 16px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 51, 107, 0.2) 0px 4px 6px -1px, rgba(0, 51, 107, 0.2) 0px 2px 4px -2px;
  transition: box-shadow 0.2s ease 0s;
}

.lio-alert-overlay .react-confirm-alert-button-group > button:hover {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 51, 107, 0.4) 0px 7px 9px -3px, rgba(0, 51, 107, 0.4) 0px 4px 6px -4px
}

.lio-alert-overlay .react-confirm-alert-button-group > .lio-alert-cancel {
  background-color: rgb(231, 233, 239);
  color: rgb(107, 114, 128);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(107, 114, 128, 0.204) 0px 4.1145px 6.17175px -1.03817px, rgba(107, 114, 128, 0.204) 0px 2.03817px 4.03817px -2.03817px;
}

.lio-alert-overlay .react-confirm-alert-button-group > .lio-alert-cancel:hover {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(172, 179, 193, 0.4) 0px 8px 12px -2px, rgba(165, 173, 187, 0.4) 0px 4px 6px -4px
}

.st0{fill:#1D3763;}
.st1{font-family:'ArialMT';}
.st2{font-size:19px;}
.st3{font-family:'MyriadPro-Regular';}
.st4{font-size:9px;}
.st5{font-size:26px;}
.st6{fill:none;stroke:#E30613;stroke-miterlimit:10;}
.st7{fill:none;stroke:#1D3763;stroke-miterlimit:10;}